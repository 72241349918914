<template>
	<div class="home">
		<a-breadcrumb>
			<a-breadcrumb-item href="">
				<a-icon type="home" />
			</a-breadcrumb-item>
			<a-breadcrumb-item href="">
				<a-icon type="schedule" />
				<span>管理</span>
			</a-breadcrumb-item>
			<a-breadcrumb-item> 内容分类 </a-breadcrumb-item>
		</a-breadcrumb>

		<div class="search-list-box">

			<div class="search-result-list">
				<div class="table-header-box">
					<div class="left">
						<h3 class="title">内容分类</h3>
					</div>
					<div class="right">
						<a-button type="primary" class="marginr20" @click="showAddEdit('add')">
							新增
						</a-button>
						<a-button type="primary" class="marginr20" @click="showAddEdit('edit')">
							详情
						</a-button>
						<a-button type="primary" class="marginr20" @click="deleteData">
							删除
						</a-button>

					</div>
				</div>

				<a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
					:columns="columns" :total="total_page * 10" :data-source="equiplist" rowKey="cate_id" size="small">
				</a-table>
			</div>
		</div>

		<a-modal v-model="addEditShow" :title="isAdd ? '新增分类' : '编辑分类'" :footer="null">
			<a-form-model ref="ruleForm" :model="info" :rules="rules" :label-col="{ span: 8 }"
				:wrapper-col="{ span: 12 }" style="margin: 0 auto; width: 80%">
				<a-row :gutter="24">
					<a-col :span="24">
						<a-form-model-item label="名称：" prop="cate_name">
							<a-input v-model="info.cate_name" />
						</a-form-model-item>
					</a-col>
				</a-row><a-row :gutter="24">
					<a-col :span="24">
						<a-form-model-item label="排序：" prop="cate_sort">
							<a-input-number v-model="info.cate_sort" style="width:100%" :min="0" :max="99" />
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
			<div class="model-footer">
				<div class="content-btns" v-if="isAdd">
					<a-button type="primary" class="marginr20" html-type="submit" @click="add()">
						保存
					</a-button>
					<a-button class="marginr20" html-type="submit" @click="addEditShow = false">
						取消
					</a-button>
				</div>
				<div class="left" v-if="!isAdd">
					<a-button type="primary" class="marginr20" html-type="submit" @click="updateEquip">
						修改
					</a-button>
					<a-button class="marginr20" html-type="submit" @click="addEditShow = false">
						取消
					</a-button>
				</div>
				<div class="right" v-if="!isAdd" :key="editIndex">
					<a-button type="dashed" :disabled="isEditBtn('prev')" @click="editToData('prev')" class=""
						html-type="submit">
						上一条
					</a-button>
					<a-button type="dashed" :disabled="isEditBtn('next')" @click="editToData('next')" class="marginr20"
						html-type="submit">
						下一条
					</a-button>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
const columns = [
	{
		title: "名称",
		dataIndex: "cate_name",
	},
	{
		title: "排序",
		dataIndex: "cate_sort",
        width: 160
	},
	{
		title: "更新时间",
		dataIndex: "update_time",
        width: 160
	},
];

import http from '../http'
export default {
	data() {
		return {
			formKey: Math.random(),
			spinning: false,
			modelVisible: false,
			expand: false,
			form: this.$form.createForm(this, {
				name: "advanced_search"
			}),
			columns,
			show1: false,
			addEditShow: false,
			isAdd: false,
			searchData: {
				page: 1
			},
			equiplist: [],
			total_page: 0,
			info: {
				cate_id: 0,
				cate_name: '',
			},
			rules: {
				cate_name: [{
					required: true,
					message: '请输入名称',
					trigger: 'blur'
				},],
				cate_sort: [{
					required: true,
					message: '请输入排序数字',
					trigger: 'blur'
				},],
			},
			selectedRowKeys: [],
			selectedRowDataList: [],
			editIndex: 0
		};
	},
	mounted() {
		this.getList()
	},
	methods: {
		editToData(type) {
			let index = -1
			if (type == 'prev') {
				index = this.editIndex - 1
				if (index == 0) {
					index = 0
				}
			} else if (type == 'next') {
				index = this.editIndex + 1
			}
			this.editIndex = index
			this.info = this.selectedRowDataList[this.editIndex]
		},
		isEditBtn(type) {
			if (type == 'prev') {
				if (this.editIndex == 0) {
					return true
				}
				if (this.selectedRowDataList == 1) {
					return true
				}
				if (this.editIndex >= 1) {
					return false
				}
			} else if (type == 'next') {
				if ((this.selectedRowDataList.length - 1) == this.editIndex) {
					return true
				}
			}
		},
		onSelectChange(selectedRowKeys) {
			this.selectedRowKeys = selectedRowKeys;
		},
		showAddEdit(type) {
			if (type == 'add') {
				this.info = {
					cate_id: 0,
					cate_name: '',
				}
				this.isAdd = true
			} else if (type == 'edit') {
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择分类');
					return
				}
				let selectDatas = []
				this.equiplist.forEach((v) => {
					if (this.selectedRowKeys.includes(v.cate_id)) {
						selectDatas.push(v)
					}
				})
				this.selectedRowDataList = JSON.parse(JSON.stringify(selectDatas))
				this.editIndex = 0
				this.info = this.selectedRowDataList[0]
				this.isAdd = false
			}
			this.addEditShow = true
		},
		edit(data) {
			this.form = {
				...data
			}
			this.modelVisible = true;
		},
		search() {
			this.searchData.page = 1
			this.getList()
		},
		getList() {
			http({
				method: 'post',
				url: '/api.cate.list/',
				data: this.searchData
			}).then((res) => {
				this.equiplist = res.data.list
				this.total_page = res.data.total_page
			})
		},
		deleteData() {
			if (this.selectedRowKeys.length == 0) {
				this.$message.warning('请先选择分类');
				return
			}
			this.$confirm({
				title: '是否删除选中分类?',
				okText: '确定',
				okType: 'danger',
				cancelText: '取消',
				onOk: () => {
					let selectDatas = []
					this.equiplist.forEach((v) => {
						if (this.selectedRowKeys.includes(v.cate_id)) {
							selectDatas.push(v.cate_id)
						}
					})
					http({
						method: 'post',
						url: '/api.cate.del/',
						data: {
							cate_id: selectDatas.join(),
						}
					}).then((res) => {
						if (res.code == '200') {
							this.$message.success('删除分类成功');
							this.getList()
						} else {
							this.$message.error(res.data);
						}
					})
				}
			});
		},
		add(type) {
			this.$refs.ruleForm.validate(valid => {
				delete this.info.count
				if (valid) {
					http({
						method: 'post',
						url: '/api.cate.add/',
						data: { ...this.info }
					}).then((res) => {
						if (res.code == '200') {
							if (!type) {
								this.addEditShow = false
							}
							this.$message.success(type == 'edit' ? '修改分类成功' : '添加分类成功');
							this.getList()
						} else {
							this.$message.error(res.data);
						}
					})
				} else {
					this.$message.warning('请检查是否已经输入必填项!');
					return false;
				}
			});
		},
		updateEquip() {
			this.add('edit')
		}
	},
};
</script>
<style lang="less">
.ant-advanced-search-form {
	padding: 24px;
	background: #fbfbfb;
	border: 1px solid #d9d9d9;
	border-radius: 6px;
}

.ant-advanced-search-form .ant-form-item {
	display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
	flex: 1;
}

.search-list-box .ant-form {
	max-width: none;
}

.search-list-box .search-result-list {
	margin-top: 16px;
	border: 1px dashed #e9e9e9;
	border-radius: 6px;
	background-color: #fafafa;
	min-height: 200px;
	text-align: center;
	padding-top: 80px;
}

.tes {
	width: 90%;
	margin: 0 auto;
	border: 1px solid #e8e8e8;
	border-radius: 3px;

	.table-th {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #e8e8e8;
		height: 30px;

		div {
			width: 33.33%;
			box-sizing: border-box;
			height: 30px;
			padding-left: 20px;
			font-weight: 700;
			border-right: 1px solid #e8e8e8;
			display: flex;
			align-items: center;

			&:last-child {
				border-right: none;
			}
		}
	}

	.taes {
		display: flex;
		align-items: center;

		&>div {
			width: 33.33%;
		}

		.chebox {
			overflow: hidden;
			box-sizing: border-box;
			height: 50px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid #e8e8e8;

			&:last-child {
				border-bottom: none;
			}

			.chebox-item {
				box-sizing: border-box;
				padding-left: 20px;
			}
		}

		.left {
			height: 300px;
			border-right: 1px solid #e8e8e8;

			.chebox {
				height: 100%;
			}
		}

		.contet {
			height: 300px;
			border-right: 1px solid #e8e8e8;
		}

		.right {
			height: 300px;
		}
	}
}
</style>
